import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent } from '@mui/material';
import {
  updateProductsListScrollPos,
  updateSearchWord,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { removeFirstLastSpaces } from '@utils/const';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FilterCondition, FetchFilteredCategories } from 'types/productSearch';
import { InferType, object, string } from 'yup';

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

/**
 * - orgamisms/products
 * @param fetchFilteredCategories
 * @returns
 */
export const useProductsSearch = (
  fetchFilteredCategories: FetchFilteredCategories
) => {
  const dispatch = useAppDispatch();
  const [categoryOptions, setCategoryOptions] = useState([
    {
      id: 1,
      label: '全カテゴリ',
    },
  ]);
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    category: '',
    tags: [] as string[],
    isSalesEnded: true,
    name: '',
  });

  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  const { searchWord } = useAppSelector(selectPlanogramEditorState);

  const { selectedProductCompartment, isShowProductDetail } = useAppSelector(
    selectPlanogramEditorState
  );

  const [isSearch, setIsSearch] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const focus = () => {
    setIsFocused(true);
  };

  const [selectedCategory, setSelectedCategory] = useState('全カテゴリ');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSalesEnded, setIsSalesEnded] = useState(true);

  const handleChangeSelectedCategory = (e: SelectChangeEvent) => {
    if (!e.target.value) return;
    setSelectedCategory(e.target.value);
  };

  const handleChangeSelectedTag = (tags: string[]) => {
    setSelectedTags(tags);
  };
  const handleChangeSalesEnded = () => {
    setFilterCondition({
      ...filterCondition,
      isSalesEnded: !isSalesEnded,
    });
    setIsSalesEnded(!isSalesEnded);
  };

  const search = useCallback(
    (data: FormData) => {
      const text = removeFirstLastSpaces(data.searchText);
      if (text === '') return;
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateSearchWord(text));
    },
    [dispatch]
  );

  const handleShowSearchCondition = () => {
    setIsSearch(true);
    setIsSearchResult(false);
    dispatch(updateSelectedProductCompartment(undefined));
  };

  const handleSearch = () => {
    setIsSearchResult(true);
    setFilterCondition({
      category: selectedCategory,
      tags: selectedTags,
      isSalesEnded: isSalesEnded,
      name: getValues('searchText'),
    });
  };

  const reset = useCallback(() => {
    dispatch(updateSearchWord(''));
    setValue('searchText', '');
    if (isSearchResult) {
      setFilterCondition({
        ...filterCondition,
        name: '',
      });
    }
    setIsFocused(false);
    dispatch(updateProductsListScrollPos(0));
    dispatch(updateSelectedProductCompartment(undefined));
  }, [dispatch, setValue, filterCondition, isSearchResult]);

  // reset search conditions
  const handleResetConditions = () => {
    reset();
    setSelectedCategory('全カテゴリ');
    handleChangeSelectedTag([]);
    setIsSalesEnded(true);
  };

  const handleBackToList = () => {
    handleResetConditions();
    setIsSearch(false);
    setIsSearchResult(false);
  };

  const handleDeleteTip = (key: keyof FilterCondition, deleteTag?: string) => {
    if (key === 'tags') {
      const newTags = filterCondition.tags.filter((tag) => tag !== deleteTag);
      setFilterCondition({
        ...filterCondition,
        tags: newTags,
      });
      setSelectedTags(newTags);
    } else if (key === 'category') {
      setFilterCondition({
        ...filterCondition,
        category: '全カテゴリ',
      });
      setSelectedCategory('全カテゴリ');
    }
  };

  // 検索画面に戻った時に、直前の検索結果を表示する
  useEffect(() => {
    if (searchWord === '') {
      return;
    }
    const text = removeFirstLastSpaces(searchWord);
    setIsFocused(true);
    setValue('searchText', text);
    void fetchFilteredCategories(text);
  }, [searchWord, setValue, fetchFilteredCategories]);

  return {
    filterCondition,
    categoryOptions,
    isSearch,
    isSearchResult,
    isFocused,
    isSalesEnded,
    selectedTags,
    selectedCategory,
    control,
    searchWord,
    selectedProductCompartment,
    isShowProductDetail,
    handleSubmit,
    setValue,
    focus,
    search,
    reset,
    handleChangeSalesEnded,
    handleChangeSelectedTag,
    handleChangeSelectedCategory,
    handleResetConditions,
    handleBackToList,
    handleShowSearchCondition,
    handleSearch,
    handleDeleteTip,
    setCategoryOptions,
    setSelectedCategory,
  };
};
