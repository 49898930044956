import {
  setTags,
  updateProductsListScrollPos,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectCategoryState } from '@reducers/productCategory/selector';
import { useProductCategoriesQuery } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import {
  extractCategories,
  findTargetCategoryPath,
} from '@utils/productCategories';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProductCategory } from '../types/productCategories';

export const useProductCategories = (
  realogramProductsCategories?: string[]
) => {
  const dispatch = useDispatch();
  const { data: productCategories } = useProductCategoriesQuery();
  const [categoryType, setCategoryType] = useState<'categories' | 'list'>(
    'categories'
  );
  const { categoryHistory, categoryIndex: productCategoryIndex } =
    useAppSelector(selectCategoryState);
  const [productCategoryHistory, setProductCategoryHistory] =
    useState<ProductCategory[]>(categoryHistory);
  const [categoryIndex, setCategoryIndex] = useState(productCategoryIndex);
  const [filteredCategories, setFilteredCategories] = useState<
    ProductCategory[]
  >([]);
  const handleClick = (category: ProductCategory) => {
    if (!category?.sub_categories?.length) {
      setCategoryType('list');
    }
    setProductCategoryHistory([...productCategoryHistory, category]);
    setCategoryIndex(categoryIndex + 1);
  };

  const handleClickProductCategory = (category: ProductCategory) => {
    if (!productCategories?.product_category) return;
    const targetCategoryPath = findTargetCategoryPath(
      productCategories?.product_category.sub_categories,
      category
    );
    if (productCategories?.product_category && targetCategoryPath) {
      setCategoryIndex(targetCategoryPath.length);
      setProductCategoryHistory([
        productCategories?.product_category,
        ...targetCategoryPath,
      ]);
    }
    if (category.sub_categories?.length === 0) {
      setCategoryType('list');
      return;
    }
    setCategoryType('categories');
  };

  const handleBack = () => {
    if (categoryIndex === 0) return;
    if (categoryType === 'list') {
      setCategoryType('categories');
      dispatch(setTags([]));
      dispatch(updateProductsListScrollPos(0));
      dispatch(updateSelectedProductCompartment(undefined));
    }
    setProductCategoryHistory(productCategoryHistory.slice(0, -1));
    setCategoryIndex(categoryIndex - 1);
  };

  const handleBackResetRoot = () => {
    if (categoryIndex === 0) return;
    if (categoryType === 'list') {
      setCategoryType('categories');
      dispatch(setTags([]));
      dispatch(updateProductsListScrollPos(0));
      dispatch(updateSelectedProductCompartment(undefined));
    }
    setProductCategoryHistory(productCategoryHistory.slice(0, 1));
    setCategoryIndex(0);
  };

  const fetchFilteredCategories = useCallback(
    (searchWord: string) => {
      if (searchWord === '') {
        setFilteredCategories([]);
        return;
      }
      const categories = productCategories?.product_category?.sub_categories
        ? extractCategories(productCategories.product_category.sub_categories)
        : [];

      const filteredCategoriesByName = categories.filter((category) =>
        category.name.toLowerCase().includes(searchWord.toLowerCase())
      );
      setFilteredCategories(filteredCategoriesByName);
    },
    [productCategories?.product_category.sub_categories]
  );

  const filteredCategoriesByProductDivisionCode =
    productCategories?.product_category?.sub_categories.filter((category) =>
      realogramProductsCategories?.includes(category.name)
    );

  useEffect(() => {
    if (productCategories?.product_category && !productCategoryHistory.length) {
      setProductCategoryHistory([productCategories?.product_category]);
    }
  }, [productCategories?.product_category, productCategoryHistory.length]);

  return {
    categoryIndex,
    productCategoryHistory,
    categoryType,
    handleBack,
    handleClick,
    fetchFilteredCategories,
    filteredCategories,
    handleClickProductCategory,
    handleBackResetRoot,
    filteredCategoriesByProductDivisionCode,
  };
};
