import { api } from '@api/index';
import { Item } from '@components/organisms/bucketArea/bucketArea';
import { useBucketsContext } from '@components/pages/planogramEditor/flatPlanogram/fragments/useBucketsContext';
import { addBayPart, moveBayPart } from '@reducers/flatPlan';
import { updateBucketsExceed } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useQuery } from '@tanstack/react-query';
import { rotationAngleFlatPlanogram } from '@utils/const';
import { convertMeterToPixel } from '@utils/planogram';
import { useEffect, useRef, useState } from 'react';
import { ConnectableElement, useDrop } from 'react-dnd';
import { BayPlanBayPart } from 'types/bayPlan';
import { Bucket } from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { getBackgroundColor } from './getBackgroundColor';

type Props = {
  index: number;
  bucket: Bucket;
  comparedBottomBucketsNum: number;
  bottomBucketsNum: number;
  isExceedSize: boolean;
};

export const useBucketsData = ({
  index,
  bucket,
  comparedBottomBucketsNum,
  bottomBucketsNum,
  isExceedSize,
}: Props) => {
  const { isCompared, isEditor } = useBucketsContext();
  const dispatch = useAppDispatch();
  const {
    selectedBucketId,
    rotateDeg,
    mode,
    rotateDegInCompare,
    selectedBayPartId,
    isDragProduct,
    isSwappingBayPartMode,
  } = useAppSelector(selectPlanogramEditorState);
  const [isOnRightSide, setIsOnRightSide] = useState(true);
  const dropTargetRef = useRef<HTMLDivElement | null>(null);
  const isSelected =
    index === selectedBucketId && mode === 'CompartmentEditor' && !isCompared;
  const isSelectedInEditor =
    index === selectedBucketId && mode === 'BayEditor' && !isCompared;
  const { padding } = bucket.detail;
  const contentX = bucket.detail.content_max.x - bucket.detail.content_min.x;
  const contentZ = bucket.detail.content_max.z - bucket.detail.content_min.z;
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['bay_part', selectedBayPartId],
    queryFn: () =>
      api.getBayPartApiV1BayPartsBayPartIdGet(selectedBayPartId ?? 0),
    enabled: selectedBayPartId !== undefined,
  });
  const bottomBucketsLength = isCompared
    ? comparedBottomBucketsNum
    : bottomBucketsNum;
  // eslint-disable-next-line no-magic-numbers -- 偶数か奇数かを (回転度数%2)=== 0 で求める一般的な記述なため
  const isFront = (rotateDeg / rotationAngleFlatPlanogram) % 2 === 0;
  const initUpperRowIndexThreshold = index >= bottomBucketsLength;

  const isDisabledBuckets = () => {
    if (mode !== 'CompartmentEditor') return false;
    if (isFront) {
      return initUpperRowIndexThreshold;
    }
    return index < bottomBucketsLength;
  };

  const height =
    convertMeterToPixel(contentZ) +
    convertMeterToPixel(padding.front) +
    convertMeterToPixel(padding.behind);
  const width =
    convertMeterToPixel(contentX) +
    convertMeterToPixel(padding.left) +
    convertMeterToPixel(padding.right);
  const rotateDegree = isEditor ? rotateDeg : rotateDegInCompare;
  const [{ isOver, item }, drop] = useDrop<
    Item,
    unknown,
    { item?: Item; isOver?: boolean }
  >(
    () => ({
      accept: ItemTypes.BUCKET_BAY_PART,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      }),
      drop: (item) => {
        if (item.index === index) return;
        let dropIndex;
        if (
          isOnRightSide ||
          (index === 0 && !isOnRightSide) ||
          (index === bottomBucketsLength && !isOnRightSide)
        ) {
          dropIndex = index;
        } else {
          dropIndex = index - 1;
        }
        if (!dropIndex.toString()) return;
        dispatch(
          moveBayPart({
            currentIndex: item.index,
            dropIndex,
            isLeftEdgeBottomRow: index === 0 && !isOnRightSide,
            isLeftEdgeTopRow: index === bottomBucketsLength && !isOnRightSide,
          })
        );
      },
      hover: (item, monitor) => {
        if (item.index === index) return;
        const targetBoundingRect =
          dropTargetRef?.current?.getBoundingClientRect();
        if (!targetBoundingRect) return;
        const horizontalMiddle =
          (targetBoundingRect.right - targetBoundingRect.left) / 2;
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) return;
        const hoverClientX = clientOffset.x - targetBoundingRect.left;
        const onRightSide = hoverClientX >= horizontalMiddle;
        setIsOnRightSide(onRightSide);
      },
    }),
    [isOnRightSide]
  );
  const isSameItem = item?.index === index;
  const paddingValue = convertMeterToPixel(
    bucket.detail.padding.right + bucket.detail.padding.left
  );

  const refCb = (node: unknown) => {
    dropTargetRef.current = node as HTMLDivElement;
    return drop(node as ConnectableElement);
  };

  const background =
    isEditor && !isCompared
      ? getBackgroundColor(isSelected, isDisabledBuckets(), mode, isDragProduct)
      : 'transparent';

  useEffect(() => {
    dispatch(updateBucketsExceed({ bucketIndex: index, isExceedSize }));
  }, [isExceedSize, mode, index, dispatch]);

  const isDisplayLeftEdgesAddBayPart =
    selectedBayPartId &&
    !isCompared &&
    !selectedBucketId?.toString() &&
    // left edges of top and botoom rows
    (index === 0 || bottomBucketsLength === index);

  const handleAddBayPart = () => {
    dispatch(
      addBayPart({
        bayPart: data?.bay_part as BayPlanBayPart,
        index,
        isLeftEdgeBottomRow: index === 0,
      })
    );
  };

  return {
    height,
    width,
    background,
    mode,
    isSwappingBayPartMode,
    selectedBucketId,
    bottomBucketsLength,
    selectedBayPartId,
    rotateDegree,
    isOver,
    isSameItem,
    isOnRightSide,
    paddingValue,
    isDisplayLeftEdgesAddBayPart,
    isDisabledAddBayPart: isLoading || isFetching,
    isCompared,
    isSelected,
    isSelectedInEditor,
    initUpperRowIndexThreshold,
    isDisabledBuckets: isEditor ? isDisabledBuckets() : false,
    refCb,
    handleAddBayPart,
  };
};
