import { DialogField } from '@components/molecules/dialogField/dialogField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { RealogramCandidate } from 'types/realogram';
import { Format, format } from '@utils/date';

type Props = {
  open: boolean;
  onClose: () => void;
  realogramCandidate?: RealogramCandidate;
};

export const RealogramInfoDialog: FC<Props> = ({
  realogramCandidate,
  open,
  onClose,
}) => {
  const recognizerVersion =
    realogramCandidate?.detail?.recognizer_version ?? '';
  const storeBayCategory =
    realogramCandidate?.store_bay?.categories.join(',') ?? '';
  const createdAt =
    (realogramCandidate &&
      format(realogramCandidate.created_at, Format.datetimeWithWeekday)) ??
    '';
  const realogramName = realogramCandidate?.store_bay.name ?? '';
  const fullName = realogramCandidate?.owner.fullname ?? '';
  const storeName = realogramCandidate?.store_bay.store.name ?? '';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '400px',
          minHeight: '261px',
          borderRadius: 2,
        },
      }}
    >
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
          pt: 3,
          gap: 1,
        }}
      >
        <Typography
          sx={{
            color: '#000000',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '20px',
            mb: 2,
          }}
        >
          スキャン結果情報
        </Typography>
        <DialogField title="スキャン日時" value={createdAt} />
        <Divider />
        <DialogField title="対象" value={storeBayCategory} />
        <Divider />
        <DialogField title="認識モデルバージョン" value={recognizerVersion} />
        <Divider />
        <DialogField title="店舗名" value={storeName} />
        <Divider />
        <DialogField title="什器名" value={realogramName} />
        <Divider />
        <DialogField title="作成者" value={fullName} />
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            m: 1,
            mr: 2,
            px: 2,
          }}
          onClick={onClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
