import { api } from '@api/index';
import { Product, ProductPageResponse } from '@api/types/generated';
import { getNextPage, mapper } from '@api/utils/pagenation';
import { FilterCondition } from '@components/organisms/productsSelectOnly/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { itemsPerPage } from '@utils/product';

type Props = {
  searchWord: string;
  categoryType: string;
  isRealogramSalesEnded: boolean;
  productCategoryName: string;
  isSearchResults: boolean;
  filterCondition: FilterCondition;
  tags: string[];
};

export const useProductsApi = ({
  isRealogramSalesEnded,
  productCategoryName,
  isSearchResults,
  filterCondition,
  tags,
}: Props) => {
  const { data, isLoading, isFetching, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      enabled: !isSearchResults,

      queryKey: ['products', isRealogramSalesEnded, productCategoryName],
      queryFn: ({ pageParam = 0 }) => {
        return api.getListApiV1ProductsGet({
          offset: pageParam as number,
          limit: itemsPerPage,
          detail: true,
          shape: true,
          category: [productCategoryName],
          tags,
          sales_ended: !isRealogramSalesEnded,

          first_order: 'sales_date_order',
        });
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
      select: (data) => {
        return {
          products: mapper(data?.pages, 'products'),
          pager: data.pages.at(0)?.pager,
        };
      },
      refetchOnWindowFocus: false,
    });

  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    isFetchingNextPage: isSearchFetchingNextPage,
    fetchNextPage: fetchSearchNextPage,
  } = useInfiniteQuery<
    ProductPageResponse,
    Error,
    { products?: Product[]; total?: number }
  >({
    enabled: isSearchResults,
    queryKey: ['searched_products', filterCondition],
    queryFn: ({ pageParam = 0 }) => {
      return api.getListApiV1ProductsGet({
        offset: pageParam as number,
        limit: itemsPerPage,
        detail: true,
        shape: true,
        q: filterCondition.name ?? undefined,
        sales_ended: filterCondition.isSalesEnded
          ? undefined
          : filterCondition.isSalesEnded,
        tags: filterCondition.tags,
        first_order: 'sales_date_order',
        category: filterCondition.category
          ? [filterCondition.category]
          : undefined,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        products: mapper(data?.pages, 'products'),
        total: data.pages.at(0)?.pager.total,
      };
    },
    refetchOnWindowFocus: false,
  });

  return {
    data,
    searchedData,
    isLoadingNextPage: isLoading || isFetching || isFetchingNextPage,
    isLoadingSearchNextPage:
      isSearchLoading || isSearchFetching || isSearchFetchingNextPage,
    isSearchLoading,
    isLoading,
    fetchNextPage,
    fetchSearchNextPage,
  };
};
