import { Draggable } from '@components/organisms/draggable/draggable';
import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC } from 'react';
import { Product } from 'types/common';
import { Bucket, FlatOverflows } from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { Buckets } from './buckets';

type Props = {
  bucketsArr?: Bucket[];
  flatOverflows: FlatOverflows[];
  canDrag: boolean;
  movingBucketIndex?: number;

  hasBottomBuckets: boolean;
  comparedBottomBucketsNum: number;
  bottomBucketsNum: number;

  contentMinZ: number;
  contentMinX: number;
  contentMinSize: number;
  contentMaxSize: number;
  onDragStart: (index: number) => void;
  onDragEnd: VoidFunction;
};

export const BucketsList: FC<Props> = ({
  bucketsArr,
  flatOverflows,
  canDrag,
  movingBucketIndex,
  hasBottomBuckets,
  comparedBottomBucketsNum,
  bottomBucketsNum,
  contentMinZ,
  contentMinX,
  contentMinSize,
  contentMaxSize,
  onDragEnd,
  onDragStart,
}) => {
  return (
    <>
      {bucketsArr?.map((bucket, index) => {
        const { content_min, padding, content_max } = bucket.detail;
        const top =
          convertMeterToPixel(content_min.z) -
          convertMeterToPixel(padding.front) +
          contentMinZ;

        const left =
          convertMeterToPixel(content_min.x) -
          convertMeterToPixel(padding.left) +
          contentMinX;
        const isExceedSize =
          content_min.x < contentMinSize || content_max.x > contentMaxSize;
        const isOverflown = flatOverflows.find(
          (el) => el.bucketIndex === index && el.overflow
        );

        return (
          <Draggable
            key={index}
            itemType={ItemTypes.BUCKET_BAY_PART}
            data={{ bucket, product: {} as Product, isExceedSize }}
            index={index}
            canDrag={canDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          >
            <Box
              component="div"
              position="absolute"
              top={top}
              left={left}
              sx={{
                opacity: movingBucketIndex === index ? 0 : 1,
                // NOTE: Make overflown products clickable outside own bucket
                zIndex: isOverflown ? '5' : 'unset',
              }}
            >
              <Buckets
                index={index}
                bucket={bucket}
                hasBottomBuckets={hasBottomBuckets}
                isExceedSize={isExceedSize}
                comparedBottomBucketsNum={comparedBottomBucketsNum}
                bottomBucketsNum={bottomBucketsNum}
              />
            </Box>
          </Draggable>
        );
      })}
    </>
  );
};
