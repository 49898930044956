import { useAppDispatch } from '@store/index';
import { useCallback, useEffect } from 'react';
import { RealogramSelectedItem, RealogramShelfBoard } from 'types/realogram';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { BucketPlanogramPlan, PlanogramPlan } from 'types/planogram';
import { productsApi } from '@reducers/shelfAppsApi';
import {
  setSelectedBucketId,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { ProductTag, ShelfDetailView } from 'types/common';
import {
  getBucketsProductIds,
  getProductsLayout,
  isPlanogramBucketPlan,
} from '@utils/planogram';
import { updateBucketProductPosition } from '@reducers/flatPlan';
import {
  getBucketCompartmentsByView,
  getBucketPosition,
} from '@components/pages/planogramDetail/flatPlanogramDetail/utils/index';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';

type Props = {
  shelfBoards?: RealogramShelfBoard[];
  comparedPlanogramPlan?: PlanogramPlan | BucketPlanogramPlan;
  selectedItem?: RealogramSelectedItem;
  isSkip?: boolean;
  initSelectedData: {
    isSkipInit?: boolean;
    view?: ShelfDetailView;
    productTag?: ProductTag;
    onInitCompleted: (value: boolean) => void;
  };
};

export const useInitSelectedRealogram = ({
  shelfBoards,
  comparedPlanogramPlan,
  selectedItem,
  isSkip = false,
  initSelectedData: { isSkipInit, view, productTag, onInitCompleted },
}: Props) => {
  const dispatch = useAppDispatch();
  const { removeQueryParameter } = useUrlQueryParams();

  const {
    searchParams,
    operate: { getSelectedItemOfRealogram, getSelectedItemOfPlanogram },
  } = useRerenderingDetails();

  const getProductDetail = useCallback(
    async (productId: number) => {
      const { product } = await dispatch(
        productsApi.endpoints.getProduct.initiate({
          productId,
        })
      ).unwrap();
      return product;
    },
    [dispatch]
  );

  const checkHasSelectedItem = useCallback(
    (
      selectedItem: RealogramSelectedItem,
      shelfBoards?: RealogramShelfBoard[]
    ) => {
      return (shelfBoards || []).some((shelfBoard) =>
        (shelfBoard.compartments || []).some((compartment) =>
          compartment.faces.some((face) => {
            return face.id === selectedItem.item.id;
          })
        )
      );
    },
    []
  );

  const initSelectedRealogram = useCallback(() => {
    const { isEmpty, product } = getSelectedItemOfRealogram(shelfBoards);

    if (!isEmpty) {
      dispatch(
        updateSelectedRealogramItem({
          shelfBoardId: product.shelfBoardId,
          compartmentId: product.compartmentId,
          item: product.item,
        })
      );
    } else {
      dispatch(updateSelectedRealogramItem(undefined));
    }
  }, [dispatch, getSelectedItemOfRealogram, shelfBoards]);

  const initSelectedShelfPlanogram = useCallback(
    async (itemId: number, planogramPlan: PlanogramPlan) => {
      const productsLayout = planogramPlan.products_layout || [];
      const selectedProduct = productsLayout.some((item) =>
        (item.row || []).some((rowItem) => rowItem.product_id === +itemId)
      );
      if (selectedProduct) {
        const product = await getProductDetail(itemId);
        const { productsSelected } = getSelectedItemOfPlanogram(
          [product],
          productsLayout
        );
        if (productsSelected?.length) {
          dispatch(updateSelectedProductCompartment(productsSelected.at(0)));
        }
        return;
      }
      dispatch(updateSelectedProductCompartment(undefined));
    },
    [dispatch, getProductDetail, getSelectedItemOfPlanogram]
  );

  const initSelectedFlatPlanogram = useCallback(
    async (itemId: number, planogramPlan: BucketPlanogramPlan) => {
      if (!view || !productTag) return;
      const buckets = planogramPlan.frame?.detail?.buckets || [];
      const productIds = buckets.flatMap((bucket) =>
        getBucketsProductIds(bucket.detail.area)
      );
      if (productIds.includes(itemId)) {
        const product = await getProductDetail(itemId);
        const { productsSelected } = getSelectedItemOfPlanogram(
          [product],
          getProductsLayout(planogramPlan)
        );
        if (productsSelected?.length) {
          dispatch(updateSelectedProductCompartment(productsSelected.at(0)));
          const positions = getBucketCompartmentsByView(
            view,
            productTag,
            planogramPlan,
            [product]
          );

          const bucketPosition = getBucketPosition(productsSelected, positions);
          if (bucketPosition) {
            dispatch(setSelectedBucketId(bucketPosition.bucketId));
            dispatch(updateBucketProductPosition(undefined));
          }
        }
        return;
      }
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(setSelectedBucketId(undefined));
      dispatch(updateBucketProductPosition(undefined));
    },
    [dispatch, getProductDetail, getSelectedItemOfPlanogram, productTag, view]
  );

  const initSelectedPlanogram = useCallback(async () => {
    const itemId = searchParams.get('item');

    if (!itemId || !comparedPlanogramPlan) return;
    if (isPlanogramBucketPlan(comparedPlanogramPlan)) {
      await initSelectedFlatPlanogram(+itemId, comparedPlanogramPlan);
    } else {
      await initSelectedShelfPlanogram(+itemId, comparedPlanogramPlan);
    }
  }, [
    comparedPlanogramPlan,
    initSelectedFlatPlanogram,
    initSelectedShelfPlanogram,
    searchParams,
  ]);

  // 初回描画：選択中のアイテム情報
  useEffect(() => {
    const initSelectedItem = async () => {
      try {
        if (isSkipInit) return;
        await Promise.all([initSelectedPlanogram(), initSelectedRealogram()]);
        onInitCompleted(true);
      } catch (e) {
        console.error(e);
      }
    };
    void initSelectedItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not need
  }, [initSelectedRealogram]);

  useEffect(() => {
    /**
     * If the selected compartment does not exist on the display shelf board list, it will be removed.
     */
    if (!selectedItem || isSkip) return;

    const hasSelectedItem = checkHasSelectedItem(selectedItem, shelfBoards);
    if (!hasSelectedItem) {
      dispatch(updateSelectedRealogramItem(undefined));
      removeQueryParameter('item', { replace: true });
    }
  }, [
    checkHasSelectedItem,
    dispatch,
    isSkip,
    removeQueryParameter,
    selectedItem,
    shelfBoards,
  ]);
};
