export const calculateTranslatePosition = (
  translatePosition: number,
  planogramCenterPosition: number,
  leftPosition: number,
  zoomScale: number
) => {
  return Math.round(
    translatePosition + (planogramCenterPosition - leftPosition) / zoomScale
  );
};
