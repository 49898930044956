import { CircularSpinner } from '@components/molecules';
import { ProductsSearchEmptyResults } from '@components/organisms/productsSearchResults/fragments/productsSearchEmptyResults';
import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = {
  isLoading: boolean;
  isEmpty: boolean;
};

export const StateLayout: FC<PropsWithChildren<Props>> = ({
  isLoading,
  isEmpty,
  children,
}) => {
  if (isLoading)
    return (
      <Box height="100%" component="div" p={2}>
        <CircularSpinner />
      </Box>
    );
  return <>{isEmpty ? <ProductsSearchEmptyResults /> : children}</>;
};
