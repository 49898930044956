import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC } from 'react';
import { Bucket } from 'types/planogram';
import { BucketArea } from '../bucketArea/bucketArea';
import { useDisplayBucketsData } from '../frame/model/useDisplayBucketsData';
import { BucketBayPartMenu } from './fragments/bucketBayPartMenu';
import { SplitAreaPopper } from './fragments/splitAreaPopper';

type Props = {
  bucket: Bucket;
  index: number;
  initUpperRowIndexThreshold: boolean;
  isDisabledBuckets: boolean;
};

export const DisplayBuckets: FC<Props> = ({
  bucket,
  index,
  initUpperRowIndexThreshold,
  isDisabledBuckets,
}) => {
  const {
    bucketsRotateDeg,
    opacity,
    isActiveBucketArea,
    padding,
    bucketBackgroundColor,
    isCompared,
    anchorEl,
    selectedBucketId,
    bucketWidth,
    bucketHeight,
    hightLightAreaPosition,
    isDisplaySplitAreaPopper,
    open,
    isDeleteButtonDisabled,
    canSplitHorizontal,
    canSplitVertical,
    isDisplayBayPartMenu,
    isSelectedBucketId,
    bboxEnabled,
    isEditor,
    handleSwapMode,
    handleDeleteBucket,
    handleDeleteArea,
    handleSplitArea,
    handleClickCompartment,
    setHightLightAreaPosition,
    resetState,
    onHandleClickArea,
    handleBucketClick,
    getBorder,
    onDragLeave,
    onDragOver,
  } = useDisplayBucketsData({
    bucket,
    index,
    initUpperRowIndexThreshold,
    isDisabledBuckets,
  });
  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
      }}
      height="100%"
      width="100%"
      onClick={handleBucketClick}
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          display: 'flex',
          transform: `rotate(${bucketsRotateDeg}deg)`,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            opacity,

            width: '100%',
            height: '100%',
            paddingLeft: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.left)}px`
              : '',
            paddingRight: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.right)}px`
              : '',
            paddingTop: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.behind)}px`
              : '',
            paddingBottom: !isActiveBucketArea
              ? `${convertMeterToPixel(padding.front)}px`
              : '',
            backgroundColor: bucketBackgroundColor,
            borderLeft: getBorder(padding.left),
            borderRight: getBorder(padding.right),
            borderTop: getBorder(padding.behind),
            borderBottom: getBorder(padding.front),
            flexDirection:
              `${bucket.detail.area.split_axis || ''}` === 'compartment_x'
                ? 'row'
                : 'column',
          }}
          data-area-container={
            isCompared ? undefined : `data-area-container-${index}`
          }
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <BucketArea
            bucketArea={bucket.detail.area}
            handleClickCompartment={handleClickCompartment}
            anchorEl={anchorEl}
            resetState={resetState}
            onHandleClickArea={onHandleClickArea}
            selectedBucketId={selectedBucketId}
            bucketIndex={index}
            isDisabledBucket={isDisabledBuckets}
            parentAreaWidth={bucketWidth}
            parentAreaHeight={bucketHeight}
            hightLightAreaPosition={hightLightAreaPosition}
            setHightLightAreaPosition={setHightLightAreaPosition}
            bboxEnabled={bboxEnabled}
            isCompared={isCompared}
            isEditor={isEditor}
          />
        </Box>
        {isDisplaySplitAreaPopper && (
          <SplitAreaPopper
            anchorEl={anchorEl}
            open={open}
            handleClose={handleDeleteArea}
            handleSplitArea={handleSplitArea}
            isDisabledDeleteButton={isDeleteButtonDisabled}
            isDisabledSplitHorizontalButton={!canSplitHorizontal}
            isDisabledSplitVerticalButton={!canSplitVertical}
          />
        )}
        {isDisplayBayPartMenu && (
          <BucketBayPartMenu
            anchorEl={anchorEl}
            handleSwapMode={handleSwapMode}
            handleDeleteBucket={handleDeleteBucket}
            open={!!anchorEl && isSelectedBucketId}
          />
        )}
      </Box>
    </Box>
  );
};
