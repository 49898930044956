import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

export const ProductsSearchEmptyResults: FC = () => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      sx={{ color: theme.palette.textBlack.disabled }}
      gap={3}
    >
      <Typography
        sx={{
          fontSize: 14,
          letterSpacing: '0.15px',
        }}
      >
        該当する商品が見つかりませんでした。
      </Typography>
    </Box>
  );
};
