import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  isDisplayChangeFrontButton: boolean;
  handleChangeFront?: VoidFunction;
};

export const ChangeFront: FC<Props> = ({
  isDisplayChangeFrontButton,
  handleChangeFront = () => void 0,
}) => {
  return (
    <Box component="div" display="flex" gap="8px" alignItems="center">
      <Typography variant="body2">正面</Typography>
      {isDisplayChangeFrontButton && (
        <Button
          sx={{
            height: '28px',
            color: `${theme.palette.textBlack.primary}`,
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            borderRadius: '4px',
            px: '8px',
            backgroundColor: `${theme.palette.white.primary}`,
            gap: '4px',
          }}
          onClick={handleChangeFront}
        >
          <ArrowUpwardDownwardIcon
            sx={{
              color: `${theme.palette.icons.primary}`,
              height: '16px',
              width: '16px',
            }}
          />
          <Typography variant="buttonText2">切り替え</Typography>
        </Button>
      )}
    </Box>
  );
};
