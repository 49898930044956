import { usePlanogramContext } from '@hooks/usePlanogramProvider';
import { changeSidesByFaceFront, minAreaSize } from '@utils/planogram';
import { getProductSize } from '@utils/product';
import { FC, MouseEvent } from 'react';
import { FaceFrontId, Product } from 'types/common';
import { BucketArea as BucketAreaType, Position } from 'types/planogram';
import { BucketArea } from '../bucketArea';
import { BucketAreaBox } from './bucketAreaBox';

type Props = {
  index: number;
  area: BucketAreaType;
  bucketIndex: number;
  bucketArea: BucketAreaType;
  handleClickCompartment: (
    e: MouseEvent<HTMLElement>,
    position: Position[],
    product: Product,
    bucketIndex: number
  ) => void;
  path?: Position[];
  anchorEl: HTMLElement | null;
  resetState: () => void;
  selectedBucketId?: number;
  onHandleClickArea: (
    e: MouseEvent<HTMLElement>,
    bucketIndex?: number,
    path?: Position[],
    productId?: number | undefined
  ) => void;
  isDisabledBucket: boolean;
  parentAreaWidth: number;
  parentAreaHeight: number;
  offspringWidth?: number;
  offspringHeight?: number;
  bboxEnabled: boolean;
  hightLightAreaPosition?: Position[];
  setHightLightAreaPosition: (value?: Position[]) => void;
  isCompared?: boolean;
  isEditor?: boolean;
};

const half = 2;
const percentage = 100;

export const Area: FC<Props> = ({
  index,
  area,
  parentAreaHeight,
  parentAreaWidth,
  selectedBucketId,
  bucketIndex,
  onHandleClickArea,
  path,
  handleClickCompartment,
  anchorEl,
  isDisabledBucket,
  resetState,
  bucketArea,
  offspringHeight,
  offspringWidth,
  bboxEnabled,
  hightLightAreaPosition,
  setHightLightAreaPosition,
  isCompared = false,
  isEditor = false,
}) => {
  const areaCompartment = (() => bucketArea.children?.at(0)?.children?.at(0))();

  const data = usePlanogramContext();
  const originProduct = data?.productsBulk
    ?.get(areaCompartment?.product_id ?? 0)
    ?.at(0);
  const comparedProduct = data?.comparisonProductsBulk
    ?.get(areaCompartment?.product_id ?? 0)
    ?.at(0);
  const product = isCompared ? comparedProduct : originProduct;
  const productSize = (() => {
    if (!product)
      return {
        width: 0,
        height: 0,
      };

    const faceFrontSize = changeSidesByFaceFront(
      getProductSize(product),
      (areaCompartment?.face_front ?? 1) as FaceFrontId
    );

    const width = (faceFrontSize.width ?? 0) * (areaCompartment?.count?.x ?? 0);
    const height =
      (faceFrontSize.height ?? 0) * (areaCompartment?.count?.y ?? 0);
    return {
      width: width,
      height: height,
    };
  })();

  const width = (() => {
    if (offspringWidth && index === 0) {
      return parentAreaWidth - offspringWidth > minAreaSize
        ? offspringWidth
        : parentAreaWidth - minAreaSize;
    }
    if (offspringWidth && index === 1) {
      return parentAreaWidth - offspringWidth > minAreaSize
        ? parentAreaWidth - offspringWidth
        : minAreaSize;
    }
    if (bucketArea.split_axis === 'compartment_y') {
      return parentAreaWidth;
    }
    if (
      bucketArea.split_axis === 'compartment_x' &&
      areaCompartment?.type !== 'compartment'
    ) {
      return parentAreaWidth / half;
    }
    if (
      bucketArea.split_axis === 'compartment_x' &&
      areaCompartment?.type === 'compartment'
    ) {
      if (!data) return;
      if (index === 0) {
        return parentAreaWidth - productSize.width > minAreaSize
          ? productSize.width
          : parentAreaWidth - minAreaSize;
      } else {
        return parentAreaWidth - productSize.width > minAreaSize
          ? parentAreaWidth - productSize.width
          : minAreaSize;
      }
    }
    return parentAreaWidth;
  })();

  const height = (() => {
    if (offspringHeight && index === 0) {
      return parentAreaHeight - offspringHeight > minAreaSize
        ? offspringHeight
        : parentAreaHeight - minAreaSize;
    }
    if (offspringHeight && index === 1) {
      return parentAreaHeight - offspringHeight > minAreaSize
        ? parentAreaHeight - offspringHeight
        : minAreaSize;
    }
    if (bucketArea.split_axis === 'compartment_x') {
      return parentAreaHeight;
    }
    if (
      bucketArea.split_axis === 'compartment_y' &&
      areaCompartment?.type !== 'compartment'
    ) {
      return parentAreaHeight / half;
    }
    if (
      bucketArea.split_axis === 'compartment_y' &&
      areaCompartment?.type === 'compartment'
    ) {
      if (!product) return;
      if (index === 0) {
        return parentAreaHeight - productSize.height > minAreaSize
          ? productSize.height
          : parentAreaHeight - minAreaSize;
      } else {
        return parentAreaHeight - productSize.height > minAreaSize
          ? parentAreaHeight - productSize.height
          : minAreaSize;
      }
    }
  })();
  return (
    <BucketAreaBox
      index={index}
      bucketArea={area}
      selectedBucketId={selectedBucketId}
      bucketIndex={bucketIndex}
      splitAxis={bucketArea.split_axis}
      onHandleClickArea={onHandleClickArea}
      key={`area-${index}`}
      path={path}
      width={`${((width ?? 0) * percentage) / parentAreaWidth}%`}
      height={`${((height ?? 0) * percentage) / parentAreaHeight}%`}
      hightLightAreaPosition={hightLightAreaPosition}
      isCompared={isCompared}
    >
      <BucketArea
        bucketArea={area}
        handleClickCompartment={handleClickCompartment}
        path={path}
        anchorEl={anchorEl}
        resetState={resetState}
        onHandleClickArea={onHandleClickArea}
        bucketIndex={bucketIndex}
        selectedBucketId={selectedBucketId}
        isDisabledBucket={isDisabledBucket}
        parentAreaHeight={height ?? 0}
        parentAreaWidth={width ?? 0}
        productSize={productSize}
        bboxEnabled={bboxEnabled}
        hightLightAreaPosition={hightLightAreaPosition}
        setHightLightAreaPosition={setHightLightAreaPosition}
        isCompared={isCompared}
        isEditor={isEditor}
      />
    </BucketAreaBox>
  );
};
