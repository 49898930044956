import { Box, Button, Typography } from '@mui/material';
import {
  updateIsShowProductDetail,
  updateProductsListScrollPos,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { useAppDispatch } from '@store/index';
import { CommonColors } from '@utils/colors';
import { FC, MutableRefObject } from 'react';
import { Product } from 'types/common';
import { ProductImage } from '../productImage/productImage';
import { baseSizeInCompareMode } from '@utils/const';

type Props = {
  product: Product;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isCompareMode?: boolean;
};

export const ProductDrawer: FC<Props> = ({
  product,
  productsRef,
  isCompareMode,
}) => {
  const dispatch = useAppDispatch();
  const sizeProductDrawDefault = 60;
  const sizeProductDrawInCompareMode = 48;
  const baseSizeProductImg = 52;

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="div"
            sx={{
              minWidth: isCompareMode
                ? sizeProductDrawInCompareMode
                : sizeProductDrawDefault,
              height: isCompareMode
                ? sizeProductDrawInCompareMode
                : sizeProductDrawDefault,
              borderRadius: 2,
              borderColor: '#ccc',
              borderStyle: 'solid',
              borderWidth: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProductImage
              product={product}
              width={isCompareMode ? baseSizeInCompareMode : baseSizeProductImg}
              height={
                isCompareMode ? baseSizeInCompareMode : baseSizeProductImg
              }
              layout="fixed"
              objectFit="contain"
              baseSize={baseSizeProductImg}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
              WebkitLineClamp: 2,
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
              WebkitBoxOrient: 'vertical',
              mx: 2,
              ...(isCompareMode && {
                lineHeight: '21px',
                wordBreak: 'break-word',
              }),
            }}
          >
            {product.name}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(updateSelectedProductCompartment(product));
            dispatch(updateIsShowProductDetail(true));
            dispatch(
              updateProductsListScrollPos(productsRef.current?.scrollTop ?? 0)
            );
          }}
          sx={{
            ...(isCompareMode && {
              minWidth: '40px',
              height: '28px',
              padding: '0px',
            }),
            borderColor: CommonColors.appBlue,
            color: CommonColors.appBlue,
          }}
        >
          <Typography
            sx={{
              ...(isCompareMode && { fontSize: '12px', lineHeight: '12px' }),
            }}
            variant="body2"
            fontWeight="bold"
          >
            詳細
          </Typography>
        </Button>
      </Box>
    </>
  );
};
