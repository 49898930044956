import { BucketsProvider } from '@components/pages/planogramEditor/flatPlanogram/fragments/useBucketsContext';
import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { BucketPlanogramPlan } from 'types/planogram';
import { Frame } from '../frame/frame';

type Props = {
  plan: BucketPlanogramPlan;
  bboxEnabled?: boolean;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  isEditor?: boolean;
  isCompared?: boolean;
  handleChangeFront?: () => void;
};

export const FlatPlanogram: FC<Props> = ({
  plan,
  bboxEnabled = false,
  zoomScale,
  isEditor,
  isCompared = false,
  setZoomScale,
  handleChangeFront,
}) => {
  // this is used to get value for centering the planogram
  const [translatePosition, setTranslatePosition] = useState(0);
  return (
    <Box
      component="div"
      display="flex"
      sx={{ transform: `translateX(${translatePosition}px)` }}
    >
      <BucketsProvider
        value={{
          isEditor,
          plan,
          bboxEnabled,
          zoomScale,
          translatePosition,
          isCompared,
          setZoomScale,
          setTranslatePosition,
          handleChangeFront,
        }}
      >
        <Frame />
      </BucketsProvider>
    </Box>
  );
};
