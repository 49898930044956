import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { BucketPlanogramPlan } from 'types/planogram';

type BucketsContextType = {
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  isEditor?: boolean;
  bboxEnabled: boolean;
  translatePosition: number;
  setTranslatePosition: (position: number) => void;
  isCompared: boolean;
  plan: BucketPlanogramPlan;
  handleChangeFront?: () => void;
};

const bucketsContext = createContext<BucketsContextType | null>(null);

export const BucketsProvider: FC<
  PropsWithChildren<{ value: BucketsContextType }>
> = ({ children, value }) => {
  return (
    <bucketsContext.Provider value={value}>{children}</bucketsContext.Provider>
  );
};

export const useBucketsContext = (): BucketsContextType => {
  const state = useContext(bucketsContext);
  if (state === null) {
    throw new Error();
  }
  return state;
};
