import { Bucket } from 'types/planogram';

export const getNewBuckets = (
  buckets?: Bucket[],
  currentIndex?: number,
  bottomBucketsNum?: number
) => {
  if (!buckets || !bottomBucketsNum) return;
  if (!currentIndex?.toString()) return buckets;
  const isBottomRow = currentIndex < bottomBucketsNum;

  const lastBottomRowIndex = bottomBucketsNum;
  let bottomBuckets = buckets?.slice(0, lastBottomRowIndex) ?? [];
  let topBuckets = buckets?.slice(lastBottomRowIndex) ?? [];
  const targetBayPart = buckets?.at(currentIndex);

  if (!targetBayPart?.detail.content_max || !targetBayPart.detail.content_min)
    return;
  const targetBayPartWidth =
    targetBayPart?.detail.content_max.x -
    targetBayPart?.detail.content_min.x +
    targetBayPart?.detail.padding.right +
    targetBayPart?.detail.padding.left;
  if (isBottomRow) {
    const leftSideItems = bottomBuckets?.slice(0, currentIndex + 1);
    const rightSideItems = bottomBuckets?.slice(currentIndex + 1).map((el) => {
      const max = { ...el?.detail.content_max };
      const min = { ...el?.detail.content_min };
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: max.x - targetBayPartWidth,
          },
          content_min: {
            ...min,
            x: min.x - targetBayPartWidth,
          },
        },
      };
    });
    // TODO: find reason why on subtraction it doesn't work
    bottomBuckets = [...leftSideItems, ...rightSideItems];
  } else {
    const newIndex = currentIndex - lastBottomRowIndex;
    const leftSideItems = topBuckets?.slice(0, newIndex).map((el) => {
      const max = { ...el?.detail.content_max };
      const min = { ...el?.detail.content_min };
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: max.x + targetBayPartWidth,
          },
          content_min: {
            ...min,
            x: min.x + targetBayPartWidth,
          },
        },
      };
    });
    const rightSideItems = topBuckets?.slice(newIndex);
    topBuckets = [...leftSideItems, ...rightSideItems];
  }
  return [...bottomBuckets, ...topBuckets];
};
