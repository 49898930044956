import { ComparisonBayHeader } from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { FlatPlanogram } from '@components/organisms/flatPlanogram/flatPlanogram';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Button } from '@mui/material';
import { useGetStoreQuery } from '@reducers/shelfAppsApi';
import {
  appConstants,
  getDisplayValue,
  getProfitsMenu,
  paths,
  pointersDummy,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import { convertMeterToPixel, isPlanogramPtsDetail } from '@utils/planogram';
import { t } from 'i18next';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import {
  BboxColors,
  BucketPlanogramPlan,
  Planogram as PlanogramType,
} from 'types/planogram';
import { PlanButtonGroup } from '../../fragments/planButtonGroup';

type Props = {
  planogram?: PlanogramType;
  flatPlan: BucketPlanogramPlan;
  handleOpenSearchModal?: () => void;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  diffColor: string;
  bboxColors: BboxColors;
  backgroundColor: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  createdAt: string;
  isCompared?: boolean;
  isBucketType?: boolean;
  isSwappingBayPartMode?: boolean;
  isTenantSalesAnalytics?: boolean;
  isEditor?: boolean;
};

export const FlatPlanogramComparison: FC<Props> = ({
  planogram,
  flatPlan,
  handleOpenSearchModal,
  backgroundColor,
  onClickNext,
  onClickPrev,
  createdAt,
  isCompared = false,
  isBucketType = true,
  isSwappingBayPartMode,
  isTenantSalesAnalytics = false,
  isEditor = false,
}) => {
  const navigate = useNavigate();
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const { name } = planogram ?? {};
  const handleNavigateView = () => {
    if (!planogram?.id) return;
    navigate(paths.plans.navigateView(planogram.id));
  };
  const plan = flatPlan;
  const { content_min: contentMin, content_max: contentMax } =
    plan.frame.detail;
  const {
    planogramEstimatedData,
    handleChangeProfit,
    storeAreaType,
    profit,
    isLoadingEstimate,
  } = useEstimatedProfit({ planogram: planogram, editedPlan: plan });
  const contentX = contentMax.x - contentMin.x;
  const ref = useRef<HTMLDivElement>();
  const [positionLeft, setPositionLeft] = useState(0);
  const [isOverflown, setIsOverflown] = useState(false);
  const handleResize = useCallback(() => {
    if (ref.current?.getBoundingClientRect()) {
      const containerRect = ref.current.getBoundingClientRect();
      setPositionLeft(containerRect.left);
      //compare with a reserve
      if (
        containerRect.width - appConstants.reserveValue <
        convertMeterToPixel(contentX)
      ) {
        setIsOverflown(true);
      } else {
        setIsOverflown(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need to add zoomScale for recalculation
  }, [setPositionLeft, contentX, zoomScale]);

  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    { skip: !planogram?.store_id }
  );

  useEffect(() => {
    if (!ref?.current) return;
    if (!positionLeft && ref.current?.getBoundingClientRect()) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, positionLeft]);

  if (!planogram?.plan || isPlanogramPtsDetail(planogram.plan)) {
    console.error('unsupported planogram type');
    return <></>;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};
  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  return (
    <>
      {/*if wrap PlanButtonGroup in position:relative parent,*/}
      {/*Planogram won't be scrollable, because we have to put zIndex to PlanButtonGroup to make it clickable*/}
      {!isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={appConstants.zoomButtonPosition}
          zIndex={5}
          sx={{ transform: `translate(${positionLeft}px, 38px)` }}
        >
          <PlanButtonGroup
            bboxEnabled={bboxEnabled}
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            isOrientationModalOpen={false}
            isEditor={false}
            zoomScale={zoomScale}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            handleNavigateView={handleNavigateView}
            isFlatPlanogram
            isCompared
          />
        </Box>
      )}

      <Box
        ref={ref}
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
      >
        <ComparisonBayHeader
          headerStyleDirection={isBucketType ? 'row' : 'column'}
          name={name ?? ''}
          bayName={storeBayPlan?.store?.name ?? '-'}
          setOpenInfoDialog={() => setOpenInfoDialog(true)}
          isComparisonSource={!handleOpenSearchModal}
          bayHeaderActions={
            handleOpenSearchModal && (
              <Button
                variant="outlined"
                onClick={handleOpenSearchModal}
                sx={{
                  height: '28px',
                  p: '0',
                  minWidth: '28px',
                  fontSize: '12px',
                  width: '40px',
                }}
              >
                変更
              </Button>
            )
          }
        >
          {planogram?.store_id && isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                profit,
                planogramEstimatedData?.estimate.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={profit}
              selectedPointerType={storeAreaType}
              handleChangeProfitValue={handleChangeProfit}
              category="シミュレーション"
              term={term}
              disabled={isSwappingBayPartMode}
              isLoading={isLoadingEstimate}
            />
          )}
        </ComparisonBayHeader>

        <Box
          //NOTE: for overflow. do not add property except overflow
          component="div"
          flex={1}
          overflow="auto"
          sx={{
            backgroundColor,
          }}
        >
          <Box
            component="div"
            minHeight="100%"
            display="flex"
            alignItems="center"
            justifyContent={isOverflown ? 'flex-start' : 'center'}
            sx={{
              transform: `scale(${zoomScale})`,
              transformOrigin: `top left`,
              pointerEvents: isCompared ? 'none' : 'all',
              py: 3,
            }}
          >
            <FlatPlanogram
              plan={flatPlan}
              zoomScale={zoomScale}
              setZoomScale={() => void 0}
              bboxEnabled={bboxEnabled}
              isCompared={isCompared}
              isEditor={isEditor}
            />
          </Box>
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={0.25}
          px={2}
          height={40}
          sx={{
            backgroundColor: theme.palette.white.primary,
          }}
        >
          <FaceAreaControllerBox
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            createdAt={createdAt}
          />
        </Box>
      </Box>
      <PlanogramInfoDialog
        planogram={planogram}
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      />
    </>
  );
};
