import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  isDisplayBackBtn: boolean;
  categoryIndex: number;
  title: string;
  handleClick: VoidFunction;
};

export const ProductSelectTitle: FC<Props> = ({
  isDisplayBackBtn,
  handleClick,
  categoryIndex,
  title,
}) => {
  return (
    <Box component="div" display="flex" onClick={handleClick}>
      {isDisplayBackBtn && (
        <IconButton
          edge="start"
          sx={{
            width: 32,
            height: 32,
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            borderRadius: 1,
            margin: '0 8px 0 0',
          }}
        >
          <ArrowBack
            fontSize="small"
            sx={{ color: theme.palette.icons.primary }}
          />
        </IconButton>
      )}

      <Typography
        component="h2"
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          letterSpacing: '0.15px',
          cursor: categoryIndex > 0 ? 'pointer' : 'default',
          display: 'inline-flex',
          alignItems: 'center',
          lineHeight: '175%',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
