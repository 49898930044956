import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Product } from 'types/common';
import { Estimate } from 'types/planogram';

type PlanogramContextType =
  | {
      products?: Product[];
      comparisonProducts?: Product[];
      productsBulk?: Map<number, Product[]>;
      comparisonProductsBulk?: Map<number, Product[]>;
      productSalesReport?: Estimate;
      comparisonProductSalesReport?: Estimate;
      addProductToBulk?: (product: Product) => void;
      removeProductFromBulk?: (product: Product) => void;
    }
  | undefined;

const planogramContext = createContext<PlanogramContextType>(undefined);

export const PlanogramProvider: FC<
  PropsWithChildren<{ value: PlanogramContextType }>
> = ({ children, value }) => {
  const [productsBulk, setProductsBulk] = useState<
    Map<number, Product[]> | undefined
  >();

  const [comparisonProductsBulk, setComparisonProductsBulk] = useState<
    Map<number, Product[]> | undefined
  >();

  const addProductToBulk = (product: Product) => {
    setProductsBulk((prevProducts = new Map()) => {
      if (!prevProducts?.has(product.id)) {
        return prevProducts?.set(product.id, [product]);
      }
      const value = [...(prevProducts.get(product.id) ?? []), product];
      return prevProducts?.set(product.id, value);
    });
  };

  const removeProductFromBulk = (product: Product) => {
    setProductsBulk((prevProducts) => {
      const productArr = prevProducts?.get(product.id);
      if (productArr?.length && productArr?.length <= 1) {
        prevProducts?.delete(product.id);
        return prevProducts;
      }
      const value = productArr?.at(0) ?? ({} as Product);
      return prevProducts?.set(product.id, [value]);
    });
  };

  useEffect(() => {
    if (value?.products) {
      setProductsBulk(
        new Map(value?.products?.map((product) => [product.id, [product]]))
      );
    }
    if (value?.comparisonProducts) {
      setComparisonProductsBulk(
        new Map(
          value?.comparisonProducts?.map((product) => [product.id, [product]])
        )
      );
    }
  }, [value?.products, value?.comparisonProducts]);

  return (
    <planogramContext.Provider
      value={{
        productsBulk,
        comparisonProductsBulk,
        productSalesReport: value?.productSalesReport,
        comparisonProductSalesReport: value?.comparisonProductSalesReport,

        addProductToBulk,
        removeProductFromBulk,
      }}
    >
      {children}
    </planogramContext.Provider>
  );
};

export const usePlanogramContext = (): PlanogramContextType => {
  const state = useContext(planogramContext);
  return state;
};
