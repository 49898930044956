import { AddIconVertical } from '@components/organisms/displayBuckets/fragments/addIconVertical';
import { Box } from '@mui/material';
import { FC } from 'react';

type Props = {
  isDisplayLeftEdgesAddBayPart: boolean;
  isDisabledAddBayPart: boolean;
  index: number;
  bottomBucketsLength: number;
  hasBottomBuckets: boolean;
  selectedBayPartId?: number;
  isCompared: boolean;
  selectedBucketId?: number;
  top: string;
  bottom: string;
  handleAddBayPart: VoidFunction;
};

export const AddBayPart: FC<Props> = ({
  isDisplayLeftEdgesAddBayPart,
  isDisabledAddBayPart,
  index,
  bottomBucketsLength,
  hasBottomBuckets,
  selectedBayPartId,
  isCompared,
  selectedBucketId,
  top,
  bottom,

  handleAddBayPart,
}) => {
  return (
    <>
      {isDisplayLeftEdgesAddBayPart && (
        <Box
          component="div"
          position="absolute"
          sx={{
            top,
            bottom,
            left: 0,
            transform: `translate(-50%,${
              bottomBucketsLength === index || !hasBottomBuckets ? 30 : -30
            }%)`,
          }}
        >
          <AddIconVertical
            disabled={isDisabledAddBayPart}
            sx={{
              transform: `rotate(${
                index >= bottomBucketsLength || !hasBottomBuckets ? '180' : 0
              }deg)`,
            }}
            handleClick={handleAddBayPart}
          />
        </Box>
      )}

      {selectedBayPartId && !isCompared && !selectedBucketId?.toString() && (
        <Box
          component="div"
          position="absolute"
          sx={{
            top,
            bottom,
            right: 0,
            transform: `translate(50%,${
              index >= bottomBucketsLength || !hasBottomBuckets ? 30 : -30
            }%)`,
          }}
        >
          <AddIconVertical
            disabled={isDisabledAddBayPart}
            sx={{
              transform: `rotate(${
                index >= bottomBucketsLength || !hasBottomBuckets ? '180' : 0
              }deg)`,
            }}
            handleClick={handleAddBayPart}
          />
        </Box>
      )}
    </>
  );
};
