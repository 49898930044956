import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Product } from 'types/common';
import { ProductImage } from '../productImage/productImage';
import {
  baseSizeDefault,
  baseSizeInCompareMode,
  fontSizeDefault,
  fontSizeInCompareMode,
  maxLineDefault,
  maxLineInCompareMode,
} from '@utils/const';
import { theme } from 'theme';
import { t } from 'i18next';

type Props = {
  product: Product;
  isSelected: boolean;
  isCompareMode?: boolean;
};

export const ProductCard: FC<Props> = ({
  product,
  isSelected,
  isCompareMode,
}) => {
  const size = isCompareMode ? baseSizeInCompareMode : baseSizeDefault;
  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        border: isSelected ? `1px solid ${theme.palette.primary.main}` : '',
        backgroundColor: isSelected ? '#E5F0FF' : '',
        position: 'relative',
        height: 72,
        px: 0.5,
        padding: '0px',
        ...(isCompareMode && {
          height: '96px',
        }),
      }}
    >
      <CardActionArea
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          padding: '8px 4px',
          ...(isCompareMode && {
            padding: '10px 8px',
            flexDirection: 'column',
            marginBottom: '-10px',
          }),
        }}
      >
        <Box
          component="div"
          sx={{
            mr: 0.5,
            width: size,
            height: size,
            minWidth: size,
            maxWidth: size,
            position: 'relative',
          }}
        >
          <ProductImage
            product={product}
            layout="fill"
            objectFit="contain"
            imageSizeType="tiny"
            baseSize={size}
          />
        </Box>
        <StyledCardContent
          isCompareMode={isCompareMode}
          name={product.name}
          productTag={product.detail?.tags}
        />
      </CardActionArea>
    </Card>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
export const StyledCardContent: FC<{
  name: string;
  isCompareMode?: boolean;
  productTag?: string[];
}> = ({ name, isCompareMode, productTag }) => {
  return (
    <CardContent
      sx={{
        py: 1,
        px: 0.5,
        flexGrow: 1,
        ...(isCompareMode && {
          width: '100%',
        }),
        overflow: 'hidden',
      }}
    >
      {productTag?.includes('推奨取消') && (
        <Box
          component="div"
          sx={{
            height: 18,
            padding: '4px 0px',
            backgroundColor: theme.palette.backgroundBlack.active,
            borderRadius: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '52px',
          }}
        >
          <Typography sx={{ fontSize: 10, color: '#444444' }}>
            {t('sales_ended')}
          </Typography>
        </Box>
      )}
      <Typography
        sx={{
          fontSize: isCompareMode ? fontSizeInCompareMode : fontSizeDefault,
          display: '-webkit-box',
          overflow: 'hidden',
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
          WebkitLineClamp: isCompareMode
            ? maxLineInCompareMode
            : maxLineDefault,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
          WebkitBoxOrient: 'vertical',
          ...(isCompareMode && { textAlign: 'left', marginLeft: '3px' }),
        }}
      >
        {name}
      </Typography>
    </CardContent>
  );
};
