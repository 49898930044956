import { CancelOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { theme } from 'theme';

type Props = {
  text: string;
  setIsConditionModal?: VoidFunction;
  handleDelete: VoidFunction;
};

export const SearchTip = ({
  text,
  setIsConditionModal,
  handleDelete,
}: Props) => {
  return (
    <Box
      component="div"
      display="inline-flex"
      gap="4px"
      flexDirection="row"
      height="28px"
      borderRadius="40px"
      alignItems="center"
      px="12px"
      sx={{
        backgroundColor: theme.palette.primary.selected,
        fontSize: '14px',
        border: `1px solid ${theme.palette.primary.main}`,
        whiteSpace: 'nowrap',
      }}
      onClick={setIsConditionModal}
    >
      <Typography variant="body2" color={theme.palette.primary.main}>
        {text}
      </Typography>
      <CancelOutlined
        sx={{
          width: '16px',
          height: '16px',
          color: theme.palette.primary.main,
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
      />
    </Box>
  );
};
