import { CircularSpinner } from '@components/molecules';
import { SelectComparison } from '@components/molecules/selectComparison/selectComparison';
import { SelectedItemCompare } from '@components/organisms/selectedItemCompare/selectedItemCompare';
import { PlanogramComparisonDetail } from '@components/pages/planogramEditor/fragments/planogramComparisonDetail';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useSelectPrevNextRealogram } from '@hooks/useSelectPrevNextRealogram';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Divider } from '@mui/material';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import {
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { setComparisonSameIds } from '@reducers/product';
import { selectProductState } from '@reducers/product/selector';
import { updateSelectedRealogramItem } from '@reducers/realogramCandidate';
import { selectRealogramSelectedItem } from '@reducers/realogramCandidate/selector';
import { EstimateForPlanogramResponse } from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ComparisonTabModal, profitTabSales } from '@utils/const';
import { Format, format } from '@utils/date';
import { getProductsLayout } from '@utils/planogram';
import { isSelectedItem } from '@utils/realogram';
import { intersection } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Product } from 'types/common';
import { Planogram, PlanogramPlan } from 'types/planogram';
import {
  RealogramCandidateContainCurrentRole,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { theme } from '../../../../theme';
import { ProductSalesReport } from 'types/products';

type Props = {
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  planogram?: Planogram;
  plan: PlanogramPlan;
  handleMoveDownBayPart: () => void;
  handleMoveUpBayPart: () => void;
  handleClickAway: () => void;
  step?: number;
  isLoading: boolean;
  comparedPlanogram?: Planogram;
  comparedRealogram?: RealogramCandidateContainCurrentRole;
  currentSelectedType?: CurrentSelectedType;
  comparedShelfboards?: RealogramShelfBoard[];
  comparisonProductIds?: string;
  handleRecalculate: () => void;
  planogramEstimatedData?: EstimateForPlanogramResponse;
  isForbidden?: boolean;
  isBucketType?: boolean;
  isTenantSalesAnalytics?: boolean;
  products?: Product[];
  comparedAnalyticsData?: ProductSalesReport;
  comparedAnalyticsDataTerm?: string;
  disableChangeToComparedPreviousWeek?: boolean;
  disableChangeToComparedNextWeek?: boolean;
  handleComparedNextWeekTerm?: () => void;
  handleComparedPreviousWeekTerm?: () => void;
};

export const PlanogramImages: FC<Props> = ({
  step,
  handleMoveDownBayPart,
  handleMoveUpBayPart,
  planogram,
  plan,
  handleClickAway,
  isLoading,
  comparedPlanogram,
  comparedRealogram,
  currentSelectedType,
  comparedShelfboards,
  comparisonProductIds,
  handleRecalculate,
  planogramEstimatedData,
  isForbidden = false,
  isBucketType = false,
  isTenantSalesAnalytics = false,
  products,
  comparedAnalyticsData,
  comparedAnalyticsDataTerm,
  disableChangeToComparedPreviousWeek,
  disableChangeToComparedNextWeek,
  handleComparedNextWeekTerm,
  handleComparedPreviousWeekTerm,
}) => {
  const dispatch = useAppDispatch();
  const selectedRealogramItem = useAppSelector(selectRealogramSelectedItem);
  const [isComparedImageLoading, setIsComparedImageLoading] = useState(false); //realogramImageが完全に読み込まれたことを確認するフラグ
  const { updateQueryParameter, removeQueryParameter } = useUrlQueryParams();

  const { comparisonSameIds } = useAppSelector(selectProductState);
  const showDevFeatureDialog = () => {
    console.log('open modal');
  };
  const { handleOpenComparisonItemModal } = useComparisonItemModal();
  const { detailMode, detailView, isSwappingBayPartMode } = useAppSelector(
    selectPlanogramEditorState
  );
  const rateValue = 'スコア';

  useEffect(() => {
    if (!planogram || !comparisonProductIds) return;
    const productIds = (
      planogram?.plan ? getProductsLayout(planogram.plan) : []
    ).flatMap(({ row }) => row.map(({ product_id }) => product_id));
    const source = comparisonProductIds?.split(',').map(Number);

    dispatch(setComparisonSameIds(intersection(productIds, source)));
  }, [dispatch, comparisonProductIds, planogram]);

  const {
    realogramEstimatedData,
    handleChangeProfit,
    storeAreaType: comparedStoreAreaType,
    profit: comparedProfit,
    planogramEstimatedData: comparedPlanogramEstimatedData,
  } = useEstimatedProfit({
    realogram: comparedRealogram,
    planogram: comparedPlanogram,
  });

  const handleClickRealogramBbox = (item: RealogramSelectedItem) => {
    dispatch(updateProductPosition(undefined));

    if (isSelectedItem(detailView, item, selectedRealogramItem)) {
      dispatch(updateSelectedRealogramItem(undefined));
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateIsShowProductDetail(false));
      removeQueryParameter('item');
      return;
    }

    if (item?.item?.primary_candidate?.product_id) {
      updateProductCompartmentBySelectedRealogram(
        item?.item?.primary_candidate?.product_id
      );
    } else {
      // NOTE: This is for displaying empty drawer data
      dispatch(updateSelectedProductCompartment({} as Product));
      dispatch(updateIsShowProductDetail(true));
    }
    dispatch(updateSelectedRealogramItem(item));

    updateQueryParameter('item', item.compartmentId);
  };

  const updateProductCompartmentBySelectedRealogram = (productId: number) => {
    const foundProductCompartment = (products || []).find(
      (product) => product.id === productId
    );
    if (!foundProductCompartment) {
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateIsShowProductDetail(false));
      return;
    }
    dispatch(updateSelectedProductCompartment(foundProductCompartment));
    dispatch(updateIsShowProductDetail(true));
  };

  const { onClickNext, onClickPrev } =
    useSelectPrevNextRealogram(comparedRealogram);
  return (
    <Box
      component="div"
      display="flex"
      flex={1}
      height="100%"
      overflow="hidden"
    >
      <Box
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        overflow="auto"
      >
        {comparedPlanogram || comparedRealogram ? (
          <SelectedItemCompare
            currentSelectedType={currentSelectedType}
            comparedPlanogram={comparedPlanogram}
            comparedRealogram={comparedRealogram}
            comparedProfit={comparedProfit}
            rateValue={rateValue}
            profitTab={profitTabSales}
            comparedRealogramEstimatedData={realogramEstimatedData}
            comparedStoreAreaType={comparedStoreAreaType}
            comparedShelfboards={comparedShelfboards}
            detailView={detailView}
            detailMode={detailMode}
            comparisonSameIds={comparisonSameIds}
            isComparedImageLoading={isComparedImageLoading}
            handleChangeComparedProfit={handleChangeProfit}
            handleClickRealogramBbox={handleClickRealogramBbox}
            setIsComparedImageLoading={setIsComparedImageLoading}
            handleOpenComparisonItemModal={handleOpenComparisonItemModal}
            showDevFeatureDialog={showDevFeatureDialog}
            handleRecalculate={() => void 0}
            planogram={planogram}
            comparedPlanogramEstimatedData={comparedPlanogramEstimatedData}
            isSwappingBayPartMode={isSwappingBayPartMode}
            isComparedBucketType={
              comparedRealogram?.shot_type === 'split_bucket'
            }
            isBucketType={isBucketType}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            isEditor
            comparedAnalyticsData={comparedAnalyticsData}
            comparedAnalyticsDataTerm={comparedAnalyticsDataTerm}
            disableChangeToComparedPreviousWeek={
              disableChangeToComparedPreviousWeek
            }
            disableChangeToComparedNextWeek={disableChangeToComparedNextWeek}
            handleComparedNextWeekTerm={handleComparedNextWeekTerm}
            handleComparedPreviousWeekTerm={handleComparedPreviousWeekTerm}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        ) : (
          <Box component="div" height="100%">
            {isLoading && (
              <CircularSpinner sx={{ display: 'flex', alignItems: 'center' }} />
            )}
            {!isLoading && (
              <SelectComparison
                emptyTopBoxSx={{ height: '43px' }}
                handleOpenModal={() =>
                  handleOpenComparisonItemModal({
                    defaultTab: ComparisonTabModal.PLANOGRAM_TAB,
                    originPlanogramId: planogram?.id,
                  })
                }
                isForbidden={
                  isForbidden && !(comparedPlanogram || comparedRealogram)
                }
              />
            )}
          </Box>
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <PlanogramComparisonDetail
        key="editor"
        handleClickAway={handleClickAway}
        handleOpenSearchModal={showDevFeatureDialog}
        backgroundColor={theme.palette.shelf.backgroundTana}
        detailView={detailView}
        detailMode={detailMode}
        createdAt={format(planogram?.created_at, Format.datetimeWithWeekday)}
        plan={plan}
        isEditor
        step={step}
        handleMoveDownBayPart={handleMoveDownBayPart}
        handleMoveUpBayPart={handleMoveUpBayPart}
        planogram={planogram}
        isRightSide
        handleRecalculate={handleRecalculate}
        planogramEstimatedData={planogramEstimatedData}
        isBucketType={isBucketType}
        isSwappingBayPartMode={isSwappingBayPartMode}
        isTenantSalesAnalytics={isTenantSalesAnalytics}
      />
    </Box>
  );
};
