import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC } from 'react';
import { BucketsList } from './fragments/bucketsList';
import { ChangeFront } from './fragments/ChangeFront';
import { FrameBorder } from './fragments/frameBorder';
import { useFrameData } from './model/useFrameData';

export const Frame: FC = () => {
  const {
    rotateDegree,
    isRotateTransitionFlatPlanogram,
    padding,
    contentX,
    contentZ,
    canDrag,

    hasBottomBuckets,
    comparedBottomBucketsNum,
    bottomBucketsNum,
    flatOverflows,
    movingBucketIndex,
    contentMinZ,
    contentMinX,
    contentMinSize,
    contentMaxSize,
    isDisplayChangeFrontButton,

    bucketsArr,
    handleChangeFront,
    onDragStart,
    onDragEnd,
  } = useFrameData();

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        transition:
          rotateDegree > 0 && isRotateTransitionFlatPlanogram
            ? 'transform 1s'
            : '',
        transform: `rotate(${rotateDegree}deg)`,
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'relative',
          // margin is added so that the element is not pressed to the edges
          marginX: 2,
        }}
      >
        <FrameBorder padding={padding} />
        <Box
          component="div"
          position="relative"
          sx={{
            width: convertMeterToPixel(contentX),
            height: convertMeterToPixel(contentZ),
            boxSizing: 'content-box',
            paddingLeft: `${convertMeterToPixel(padding.left)}px`,
            paddingRight: `${convertMeterToPixel(padding.right)}px`,
            paddingTop: `${convertMeterToPixel(padding.behind)}px`,
            paddingBottom: `${convertMeterToPixel(padding.front)}px`,
          }}
        >
          <BucketsList
            bucketsArr={bucketsArr}
            canDrag={canDrag}
            hasBottomBuckets={hasBottomBuckets}
            comparedBottomBucketsNum={comparedBottomBucketsNum}
            bottomBucketsNum={bottomBucketsNum}
            flatOverflows={flatOverflows}
            movingBucketIndex={movingBucketIndex}
            contentMinZ={contentMinZ}
            contentMinX={contentMinX}
            contentMinSize={contentMinSize}
            contentMaxSize={contentMaxSize}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        </Box>
      </Box>
      <ChangeFront
        handleChangeFront={handleChangeFront}
        isDisplayChangeFrontButton={isDisplayChangeFrontButton}
      />
    </Box>
  );
};
