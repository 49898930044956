import { ProductDrawer } from '@components/organisms';
import { Categories } from '@components/organisms/categories/categories';
import { ProductsHeader } from '@components/pages/planogramEditor/fragments/productsHeader/productsHeader';
import { Box, Divider, SelectChangeEvent } from '@mui/material';
import { FC, RefObject } from 'react';
import { Control } from 'react-hook-form';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { RecentProducts } from './recentProducts/recentProducts';
import { FormValues, SearchedData } from 'types/products';
import { ProductsSearchResults } from './productsSearch/productsSearchResult';
import { FilterCondition } from 'types/productSearch';
import { ProductsSearchFields } from './productsSearch/productsSearchFields';

type Props = {
  categoryOptions: { id: number; label: string }[];
  isSearch: boolean;
  isSearchResult: boolean;
  isFocused: boolean;
  isSalesEnded: boolean;
  filterCondition: FilterCondition;
  selectedTags: string[];
  selectedCategory: string;
  displayRecentProducts: boolean;
  control: Control<FormValues>;
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
  recentProducts: Product[];
  isCompareMode: boolean;
  selectedProductCompartment?: Product;
  searchWord: string;
  categoryType: 'categories' | 'list';
  productsRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  isSearchedLoading: boolean;
  data: SearchedData;
  offset: number;
  isPlanogramSalesEnded: boolean;
  isFetchingNextPage: boolean;
  isSearchFetchingNextPage: boolean;
  searchedData: SearchedData;
  filteredCategories: ProductCategory[];
  isShowProductDetail: boolean;
  tags: string[];
  focus: VoidFunction;
  onClearSearchClick: VoidFunction;
  onCategoryTitleClick: VoidFunction;
  onClickRecentProducts: VoidFunction;
  handleClickProduct: (product: Product) => void;
  handleClick: (category: ProductCategory) => void;
  handleEndReached: (index: number) => void;
  handleChangeSalesEnded: VoidFunction;
  filteredCategoriesReset: VoidFunction;
  handleClickProductCategory: (category: ProductCategory) => void;
  handleSearchEndReached: (index: number) => void;
  handleSubmit: VoidFunction;
  handleTagClick: (tag?: string) => void;
  handleResetConditions: VoidFunction;
  handleBackToList: VoidFunction;
  handleChangeSelectedCategory: (e: SelectChangeEvent) => void;
  handleChangeSelectedTag: (tags: string[]) => void;
  handleDeleteTip: (key: keyof FilterCondition, deleteTag?: string) => void;
  handleShowSearchCondition: VoidFunction;
  handleSearch: VoidFunction;
};

export const ProductTabPanel: FC<Props> = ({
  categoryOptions,
  isSearch,
  isSearchResult,
  isFocused,
  isSalesEnded,
  filterCondition,
  selectedTags,
  selectedCategory,
  displayRecentProducts,
  control,
  isFetchingNextPage,
  isSearchFetchingNextPage,
  onClearSearchClick,
  onCategoryTitleClick,
  categoryIndex,
  productCategoryHistory,
  onClickRecentProducts,
  recentProducts,
  isCompareMode,
  handleClickProduct,
  selectedProductCompartment,
  categoryType,
  handleClick,
  productsRef,
  handleEndReached,
  isLoading,
  isSearchedLoading,
  data,
  handleChangeSalesEnded,
  searchedData,
  filteredCategoriesReset,
  filteredCategories,
  handleClickProductCategory,
  handleSearchEndReached,
  isShowProductDetail,
  handleResetConditions,
  handleBackToList,
  handleChangeSelectedCategory,
  handleChangeSelectedTag,
  handleDeleteTip,
  handleShowSearchCondition,
  handleSearch,
}) => {
  return (
    <Box
      component="form"
      // onSubmit={handleSubmit}
      noValidate
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="hidden"
      px={1}
    >
      {isSearch ? (
        <>
          {isSearchResult ? (
            <ProductsSearchResults
              products={searchedData?.products}
              isSearchFetching={isSearchFetchingNextPage}
              reset={() => {
                filteredCategoriesReset();
              }}
              filteredCategories={filteredCategories}
              filterCondition={filterCondition}
              handleClickProductCategory={(category: ProductCategory) => {
                handleClickProductCategory(category);
              }}
              isLoading={isSearchedLoading}
              productsRef={productsRef}
              isCompareMode={isCompareMode}
              handleEndReached={handleSearchEndReached}
              total={searchedData?.pager.total}
              handleClickProduct={handleClickProduct}
              selectedItemId={selectedProductCompartment?.id ?? 0}
              handleChangeSalesEnded={handleChangeSalesEnded}
              isSalesEnded={isSalesEnded}
              isSalesEndSwitchBorderTop
              control={control}
              filteredCategoriesReset={filteredCategoriesReset}
              handleResetConditions={handleResetConditions}
              handleBackToList={handleBackToList}
              handleShowSearchCondition={handleShowSearchCondition}
              handleDeleteTip={handleDeleteTip}
            />
          ) : (
            <ProductsSearchFields
              isCompareMode={isCompareMode}
              isSalesEnded={isSalesEnded}
              control={control}
              selectedTags={selectedTags}
              categoryOptions={categoryOptions}
              selectedCategory={selectedCategory}
              handleChangeSelectedCategory={handleChangeSelectedCategory}
              filteredCategoriesReset={filteredCategoriesReset}
              handleChangeSelectedTag={handleChangeSelectedTag}
              handleChangeSalesEnded={handleChangeSalesEnded}
              handleResetConditions={handleResetConditions}
              handleBackToList={handleBackToList}
              handleSearch={handleSearch}
            />
          )}
        </>
      ) : (
        <>
          <ProductsHeader
            displaySearchInput={isFocused}
            displayActionButtons={!isFocused && !displayRecentProducts}
            control={control}
            onClearSearchClick={onClearSearchClick}
            onCategoryTitleClick={onCategoryTitleClick}
            categoryIndex={categoryIndex}
            productCategoryHistory={productCategoryHistory}
            onClickRecentProducts={onClickRecentProducts}
            displayRecentProducts={displayRecentProducts}
            handleShowSearchCondition={handleShowSearchCondition}
          />
          <Divider sx={{ mb: 1, mx: -1 }} />
          {displayRecentProducts ? (
            <RecentProducts
              products={recentProducts}
              isCompareMode={isCompareMode}
              handleClickProduct={handleClickProduct}
              selectedItemId={selectedProductCompartment?.id ?? 0}
            />
          ) : (
            <Categories
              categoryName={productCategoryHistory[categoryIndex]?.name}
              categoryType={categoryType}
              productCategories={
                productCategoryHistory[categoryIndex]?.sub_categories
              }
              handleClick={handleClick}
              productsRef={productsRef}
              isCompareMode={isCompareMode}
              handleEndReached={handleEndReached}
              isLoading={isLoading}
              products={data?.products}
              handleClickProduct={handleClickProduct}
              selectedItemId={selectedProductCompartment?.id ?? 0}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </>
      )}
      {selectedProductCompartment && !isShowProductDetail && (
        <Box
          component="div"
          sx={{
            p: 2,
            position: 'absolute',
            zIndex: 2,
            width: '100%',
            boxShadow: '5px 2px 8px rgba(0, 0, 0, 0.25)',
            backgroundColor: theme.palette.white.primary,
            ...(isCompareMode && {
              right: 0,
              bottom: 16,
              height: '80px',
            }),
            ...(!isCompareMode && {
              left: 0,
              bottom: 0,
            }),
          }}
        >
          <ProductDrawer
            isCompareMode={isCompareMode}
            product={selectedProductCompartment}
            productsRef={productsRef}
          />
        </Box>
      )}
    </Box>
  );
};
