import { Box } from '@mui/material';
import { convertMeterToPixel } from '@utils/planogram';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  padding: {
    left: number;
    right: number;
    top: number;
    bottom: number;
    front: number;
    behind: number;
  };
};

export const FrameBorder: FC<Props> = ({ padding }) => {
  return (
    <Box
      component="div"
      position="absolute"
      top={0}
      left={0}
      sx={{
        width: '100%',
        height: '100%',
        borderLeft: `${convertMeterToPixel(padding.left)}px solid ${
          theme.palette.dividerBlack.dark
        }`,
        borderRight: `${convertMeterToPixel(padding.right)}px solid ${
          theme.palette.dividerBlack.dark
        }`,
        borderTop: `${convertMeterToPixel(padding.behind)}px solid ${
          theme.palette.dividerBlack.dark
        }`,
        borderBottom: `${convertMeterToPixel(padding.front)}px solid ${
          theme.palette.dividerBlack.dark
        }`,
      }}
    />
  );
};
