import { useBucketsContext } from '@components/pages/planogramEditor/flatPlanogram/fragments/useBucketsContext';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { convertMeterToPixel, getTopBottomBuckets } from '@utils/planogram';
import { useEffect, useState } from 'react';
import { Bucket } from 'types/planogram';
import { getNewBuckets } from './getNewBuckets';

const half = 2;

export const useFrameData = () => {
  const { plan, isEditor, isCompared, handleChangeFront } = useBucketsContext();
  const [bottomBucketsNum, setBottomBucketsNum] = useState(0);
  const {
    rotateDeg,
    rotateDegInCompare,
    mode,
    isSwappingBayPartMode,
    flatOverflows,
    isRotateTransitionFlatPlanogram,
  } = useAppSelector(selectPlanogramEditorState);
  const [movingBucketIndex, setMovingBucketIndex] = useState<
    number | undefined
  >();

  const {
    content_min: contentMin,
    content_max: contentMax,
    padding,
    buckets,
  } = plan.frame.detail;
  const [newBuckets, setNewBuckets] = useState(buckets);

  const contentZ = contentMax.z - contentMin.z;
  const contentX = contentMax.x - contentMin.x;
  const contentMinZ = convertMeterToPixel(contentMin.z);
  const contentMinX = convertMeterToPixel(contentMin.x);
  const rotateDegree = isEditor || !isCompared ? rotateDeg : rotateDegInCompare;
  const onDragStart = (index: number) => {
    setMovingBucketIndex(index);
  };
  const onDragEnd = () => {
    setMovingBucketIndex(undefined);
  };

  useEffect(() => {
    if (buckets?.length) {
      const bottomBuckets = getTopBottomBuckets(
        buckets,
        (plan.frame.detail.content_max.z - plan.frame.detail.content_min.z) /
          half
      );
      setBottomBucketsNum(bottomBuckets?.bottom.length);
    }
  }, [
    buckets,
    plan.frame.detail.content_max.z,
    plan.frame.detail.content_min.z,
    setBottomBucketsNum,
  ]);

  useEffect(() => {
    if (!newBuckets?.length) {
      setNewBuckets(buckets);
    }
  }, [buckets, newBuckets?.length]);

  // this useEffect only for Chrome browser
  // without this, drag end will fire immediately and drag event will not fire
  useEffect(() => {
    if (!movingBucketIndex && !buckets?.length) return;
    const timeoutId = setTimeout(() => {
      const newBuckets = getNewBuckets(
        buckets,
        movingBucketIndex,
        bottomBucketsNum
      );
      setNewBuckets(newBuckets);
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [movingBucketIndex, bottomBucketsNum, buckets]);
  const contentMaxSize = contentMax.x - padding.right;
  const contentMinSize = contentMin.x - padding.left;
  const bucketsArr = movingBucketIndex?.toString() ? newBuckets : buckets;
  const topBottomBuckets: { top: Bucket[]; bottom: Bucket[] } | undefined =
    (() => {
      if (!bucketsArr) return undefined;
      return getTopBottomBuckets(
        bucketsArr,
        (plan.frame.detail.content_max.z - plan.frame.detail.content_min.z) /
          half
      );
    })();

  return {
    rotateDegree,
    isRotateTransitionFlatPlanogram,
    padding,
    contentX,
    contentZ,
    canDrag: mode === 'BayEditor' && !isCompared && !isSwappingBayPartMode,
    hasBottomBuckets: Boolean(topBottomBuckets?.bottom),
    comparedBottomBucketsNum: topBottomBuckets?.bottom.length ?? 0,
    bottomBucketsNum,
    flatOverflows,
    movingBucketIndex: movingBucketIndex,
    contentMinZ,
    contentMinX,
    contentMinSize,
    contentMaxSize,
    isDisplayChangeFrontButton: mode === 'CompartmentEditor' && !isCompared,

    bucketsArr,
    handleChangeFront,
    onDragStart,
    onDragEnd,
  };
};
