import { theme } from 'theme';
import { Mode } from 'types/common';

export const getBackgroundColor = (
  isSelected: boolean,
  isDisabled: boolean,
  mode: Mode,
  isDragProduct?: boolean
) => {
  if (mode !== 'CompartmentEditor') return 'transparent';
  if (isSelected && !isDragProduct) return theme.palette.white.primary;
  else if (isDisabled) return theme.palette.backgroundBlack.disabled;
  return 'transparent';
};
