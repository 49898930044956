// TODO: スキャナ階層構造実装後に削除する
import { RealogramsTableMenu } from '@components/molecules/realogramsTableMenu/realogramsTableMenu';
import { StatusIcon } from '@components/organisms';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { CommonColors } from '@utils/colors';
import { Format, format } from '@utils/date';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { Status } from 'types/common';
import { RealogramCandidate } from 'types/realogram';

type Props = {
  imageHeight: number;
  realogramCandidate: RealogramCandidate;
  handleOpenDeleteDialog: (id: number) => void;
  handleClick: (id: number, status: Status, index: number) => void;
  index: number;
};

export const RealogramCard: FC<Props> = ({
  imageHeight,
  realogramCandidate,
  handleOpenDeleteDialog,
  handleClick,
  index,
}) => {
  const { id, created_at, status, store_bay, owner } = realogramCandidate;
  const { image, error: imageError } = useRealogramCandidateImage({
    realogramCandidateId: id,
    size: 'medium',
  });
  const isProcessed = status === 'processed';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isNotEnable: isNotCanReadShare } = useGetRealogramPermission({
    action: 'read_role',
    realogram: realogramCandidate,
    isCandidate: true,
    isCan: false,
  });
  const { isNotEnable: isNotCanDelete } = useGetRealogramPermission({
    action: 'delete',
    realogram: realogramCandidate,
    isCandidate: true,
    isCan: false,
  });
  return (
    <>
      <Card
        sx={{ border: '1px solid #CCCCCC', position: 'relative' }}
        elevation={0}
        onClick={() => handleClick(id, status, index)}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            zIndex: 100,
            margin: 1,
            right: '0px',
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              p: 0,
              bgcolor: theme.palette.white.translucent,
              width: '32px',
              height: '32px',
              '&:hover': {
                bgcolor: theme.palette.white.translucent,
              },
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <CardActionArea disabled={!isProcessed}>
          {!!image && !imageError ? (
            <CardMedia
              image={image}
              height={imageHeight}
              component="img"
              sx={{
                borderBottom: '1px solid #CCCCCC',
                filter: !isProcessed ? 'opacity(50%)' : undefined,
                backgroundColor: theme.palette.white.primary,
                objectFit: 'contain',
              }}
            />
          ) : (
            <Box
              component="div"
              sx={{ background: 'rgba(0, 0, 0, 0.04)' }}
              height={imageHeight}
            />
          )}

          <CardContent
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingX: '8px',
              paddingY: '8px',
              maxHeight: '99px',
              '&:last-child': {
                paddingBottom: '8px',
              },
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '6px',
              }}
            >
              <Typography fontWeight="700" fontSize="14px">
                {store_bay.store.name}
              </Typography>
              <Box component="div" display="flex" justifyContent="center">
                <StatusIcon status={status} />
              </Box>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '11px',
                lineHeight: '11px',
                justifyContent: 'start',
                alignItems: 'center',
                mb: '6px',
              }}
            >
              {store_bay.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '11px',
                lineHeight: '11px',
                justifyContent: 'start',
                alignItems: 'center',
                color: CommonColors.grayLight,
                backgroundColor: '#EEEEEE',
                width: 'fit-content',
                mb: '6px',
                p: '4px',
              }}
            >
              {store_bay?.categories?.join(',')}
            </Typography>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '11px',
                  lineHeight: '11px',
                  color: CommonColors.grayLight,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {owner.fullname}
              </Typography>
              <Typography
                sx={{
                  variant: 'caption',
                  fontSize: '12px',
                  lineHeight: '12px',
                  color: CommonColors.grayLight,
                  whiteSpace: 'nowrap',
                  pl: '4px',
                }}
              >
                {format(created_at, Format.datetime)}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <RealogramsTableMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        handleOpenDeleteDialog={() => {
          setAnchorEl(null);
          handleOpenDeleteDialog(realogramCandidate.id);
        }}
        handleFavoriteClick={() => console.log('placeholder')}
        isCanReadShare={isNotCanReadShare}
        isCanDelete={isNotCanDelete}
      />
    </>
  );
};
