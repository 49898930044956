import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  handleClick: VoidFunction;
  displayedCount: number;
  totalCount: number;
};

export const CategoriesInfo: FC<Props> = ({
  handleClick,
  displayedCount,
  totalCount,
}) => {
  return (
    <Box
      component="div"
      sx={{
        height: 48,
        display: 'flex',
        gap: '16px',
        background: theme.palette.backgroundBlack.primary,
        alignItems: 'center',
        px: '8px',
        mt: '8px',
        borderRadius: '4px',
      }}
    >
      <Typography variant="body2">
        {`${displayedCount}/${totalCount}`}カテゴリを表示中
      </Typography>
      <Button
        onClick={handleClick}
        variant="text"
        sx={{
          fontWeight: 'bold',
        }}
      >
        全カテゴリを表示
      </Button>
    </Box>
  );
};
